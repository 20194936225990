import React, { useEffect, useState, useRef } from 'react';
import './Home.scss'
import { Input, Button, Tabs, Modal, Popover } from 'antd';
import 'bootstrap/dist/css/bootstrap.min.css';

const Home = () => {

    const [searchIndex, setSearchIndex] = useState(0)
    const [showQrcode, setShowQrcode] = useState(false)
    const [open, setOpen] = useState(false);

    useEffect(() => {

    }, [])

    const tab1Com = (
        <div>
            {citys.map((item, index) => {
                return (
                    <div className='d-flex'>
                        <div className="city-title">{item.title}</div>
                        <div className='area-box'>
                            {item.areas.map(area => {
                                return (
                                    <a className='area'>{area}</a>
                                )
                            })}
                        </div>
                    </div>
                )
            })}

        </div>
    )

    const tab2Com = (
        <div>
            {professions.map((item, index) => {
                return (
                    <div className='d-flex'>
                        <div className="pro-title">{item.title}</div>
                        <div className='area-box'>
                            {item.types.map(type => {
                                return (
                                    <a className='area'>{type}</a>
                                )
                            })}
                        </div>
                    </div>
                )
            })}
        </div>
    )

    const tab3Com = (
        <div className='company-grid'>
            {companys.map((item, index) => {
                return (
                    <div className='company'>
                        <div className='company-name'>{item.name}</div>
                        <div className='company-info'>{`成立日期：${item.date}`}</div>
                        <div className='company-info'>{`法人代表：${item.person}`}</div>
                        <div className='company-info'>{`注册资本：${item.money} 万人民币`}</div>
                        <div className='company-address'>{`地址：${item.address}`}</div>
                    </div>
                )
            })}
        </div>
    )

    const tab4Com = (
        <div className='company-grid'>
            {hots.map((item, index) => {
                return (
                    <div className='company'>
                        <div className='company-name'>{item.name}</div>
                        <div className='company-info'>{`法人代表：${item.person}`}</div>
                        <div className='company-info'>{`注册资本：${item.money} 万人民币`}</div>
                    </div>
                )
            })}
        </div>
    )

    const tab5Com = (
        <div className='person-grid'>
            {persons.map((item, index) => {
                return (
                    <div className='person'>
                        <span className='company-info'>{item}</span>
                    </div>
                )
            })}
        </div>
    )

    const menu1Com = (
        <div className='p-1' style={{ width: '456px' }} >
            {menu1List.map(item => {
                return (
                    <div className='d-flex align-items-center menu-1-item'>
                        <img style={{ width: '48px' }} src={item.icon} />
                        <div className='ms-2 flex-fill'>
                            <div className='menu-1-title'>{item.title}</div>
                            <div className='menu-1-subtitle'>{item.subtitle}</div>
                        </div>
                        <Button onClick={() => {setShowQrcode(true); setOpen(false)}}>免费试用</Button>
                    </div>
                )
            })}

        </div>
    )

    const menu2Com = (
        <div className='ps-1 pe-1'>
            {menu2List.map(item => {
                return (
                    <div className='d-flex align-items-center menu-2-item'>
                        <img style={{ width: '30px' }} src={item.icon} />
                        <div className='ms-2'>{item.title}</div>
                    </div>
                )
            })}

        </div>
    )

    const menu3Com = (
        <div className='ps-1 pe-1'>
            {menu3List.map(item => {
                return (
                    <div className='d-flex align-items-center menu-2-item'>
                        <img style={{ width: '30px' }} src={item.icon} />
                        <div className='ms-2'>{item.title}</div>
                    </div>
                )
            })}

        </div>
    )

    const menu4Com = (
        <div className='ps-1 pe-1'>
            {menu4List.map(item => {
                return (
                    <div className='d-flex align-items-center menu-2-item'>
                        <div>{item}</div>
                    </div>
                )
            })}

        </div>
    )

    const menu5Com = (
        <div className='ps-1 pe-1' style={{ width: '516px' }}>
            {menu5List.map(section => {
                return (
                    <div className='menu-2-item'>
                        <div className='menu-section'>{section.title}</div>
                        <div className='d-flex flex-row flex-wrap'>
                            {section.list.map(item => {
                                return (
                                    <div className='d-flex align-items-center menu-2-item flex-column' style={{ width: '80px' }}>
                                        <img style={{ width: '30px' }} src={item.icon} />
                                        <div className='mt-1'>{item.title}</div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )
            })}
        </div>
    )

    const handleOpenChange = (newOpen) => {
        setOpen(newOpen)
    }

    return (
        <div className='home'>
            <div className='header d-flex align-items-center'>
                <div className='container d-flex justify-content-between'>
                    <img className='logo' src={require('../../images/qifuyuan.png')} />
                    <div className='d-flex align-items-center'>
                        <Popover content={menu1Com} arrow={true} open={open} onOpenChange={handleOpenChange}>
                            <a className='menu'>企业版</a>
                        </Popover>
                        <Popover content={menu2Com} arrow={true}>
                            <a className='menu ms-4'>数据服务</a>
                        </Popover>
                        <Popover content={menu3Com} arrow={true}>
                            <a className='menu ms-4'>企业服务</a>
                        </Popover>
                        <Popover content={menu4Com} arrow={true}>
                            <a className='menu ms-4'>合作入口</a>
                        </Popover>
                        <Popover content={menu5Com} arrow={true}>
                            <a className='menu ms-4'>应用</a>
                        </Popover>
                        <a className='menu ms-4' href="https://api.byteintel.cn/index.php/api/get_api_list">开放平台</a>
                        <Button className='ms-5' type="primary" onClick={() => {setShowQrcode(true)}}>登录 | 注册</Button>
                    </div>
                </div>
            </div>
            <div className='content'>
                <section className='search'>
                    <div className='container'>
                        <h1 className="title">
                            查企业&nbsp;&nbsp;上企赋源
                        </h1>
                        <div className="d-flex justify-content-center m-t-5">
                            {
                                searchTypeList.map((item, index) => {
                                    return (
                                        <div key={index} className={'search-type ' + (index === searchIndex ? 'active' : '')} onClick={() => setSearchIndex(index)}>{item.type}</div>
                                    )
                                })
                            }
                        </div>
                        <div className="d-flex justify-content-center mt-4">
                            <Input className='search-input' placeholder={searchTypeList[searchIndex].placeholder} />
                            <Button className='search-btn' type="primary" danger onClick={() => setShowQrcode(true)}>查一下</Button>
                        </div>
                    </div>
                </section>
                <section>
                    <div className='container'>
                        <div class="row justify-content-around mt-4 mb-4">
                            {funcList.map((item, index) => {
                                return (
                                    <div class="func-item rounded shadow col-sm-2 mb-3">
                                        <img className='func-img' src={item.icon} />
                                        <div>
                                            <div className='func-title'>{item.title}</div>
                                            <div className='func-subtitle'>{item.subtitle}</div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                </section>
                <section>
                    <div className='container'>
                        <Tabs
                            size='large'
                            defaultActiveKey="1"
                            items={[
                                {
                                    label: '按地区查询',
                                    key: '1',
                                    children: tab1Com,
                                },
                                {
                                    label: '按行业查询',
                                    key: '2',
                                    children: tab2Com,
                                },
                                {
                                    label: '最新注册企业',
                                    key: '3',
                                    children: tab3Com,
                                },
                                {
                                    label: '热搜企业',
                                    key: '4',
                                    children: tab4Com,
                                },
                                {
                                    label: '热门人物',
                                    key: '5',
                                    children: tab5Com,
                                },
                            ]}
                        />
                    </div>
                </section>
            </div>
            <div className='fb-bg'>
                <div className='container d-flex justify-content-around p-5'>
                    <div>
                        <div className="d-flex align-items-end">
                            <img className='fb-icon' src={require('../../images/company.png')} />
                            <span className='fb-title'>2.3亿+企业和组织机构</span>
                        </div>
                        <div className='fb-subtitle'>实时搜索海量企业信息</div>
                    </div>
                    <div>
                        <div className="d-flex align-items-end">
                            <img className='fb-icon' src={require('../../images/search.png')} />
                            <span className='fb-title'>毫秒搜索</span>
                        </div>
                        <div className='fb-subtitle'>精确搜索无需等待，节约时间</div>
                    </div>
                    <div>
                        <div className="d-flex align-items-end">
                            <img className='fb-icon' src={require('../../images/multi.png')} />
                            <span className='fb-title'>多维信息</span>
                        </div>
                        <div className='fb-subtitle'>工商、关联、失信，多类信息齐全</div>
                    </div>
                    <div>
                        <div className="d-flex align-items-end">
                            <img className='fb-icon' src={require('../../images/report.png')} />
                            <span className='fb-title'>信用报告</span>
                        </div>
                        <div className='fb-subtitle'>快速洞察目标企业商业信用</div>
                    </div>

                </div>
            </div>
            <div className='footer'>
                <div className='container pt-4'>
                    <div className='d-flex justify-content-between'>
                        <div>
                            <img style={{ width: '160px', height: 'auto', backgroundColor: 'white' }} src={require('../../images/qifuyuan.png')} />
                        </div>
                        <div>
                            <h4 className="fast-navi">快速导航</h4>
                            <div className='link-list'>
                                <a className='navi-link' target="_blank">地区导航</a>
                                <a className='navi-link' target="_blank" >行业导航</a>
                                <a className='navi-link' target="_blank">热门搜索</a>
                                <a className='navi-link' target="_blank">人员名录</a>
                                <a className='navi-link' target="_blank">年报查询</a>
                                <a className='navi-link' target="_blank">品牌名录</a>
                                <a className='navi-link' target="_blank" >企业查询</a>
                                <a className='navi-link' target="_blank" >老板查询</a>
                                <a className='navi-link' target="_blank" >商标查询</a>
                                <a className='navi-link' target="_blank" >融资查询</a>
                                <a className='navi-link' target="_blank" >关系查询</a>
                                <a className='navi-link' target="_blank" >招标查询</a>
                                <a className='navi-link' target="_blank" >批量查询</a>
                                <a className='navi-link' target="_blank" >版权查询</a>
                                <a className='navi-link' target="_blank" >姓氏大全</a>
                                <a className='navi-link' target="_blank" >企业版</a>
                                <a className='navi-link' target="_blank" >招聘查询</a>
                                <a className='navi-link' target="_blank" >防汛救灾倡议书</a>
                                <a className='navi-link' target="_blank" > 国家网络安全宣传周 </a>
                            </div>
                        </div>
                        <div>
                            <h4 className="fast-navi">关于企赋源</h4>
                            <div className='link-list-1'>
                                <a className='navi-link' target="_blank">关于我们</a>
                                <a className='navi-link' target="_blank" >常见问题</a>
                                <a className='navi-link' target="_blank">意见反馈</a>
                                <a className='navi-link' target="_blank">服务协议</a>
                                <a className='navi-link' target="_blank">隐私政策</a>
                                <a className='navi-link' target="_blank">儿童隐私</a>

                            </div>
                        </div>
                        <div>
                            <h4 className="fast-navi">联系方法</h4>
                            <div className='link-list-1'>
                                <a className='navi-link' target="_blank">在线咨询</a>
                                <a className='navi-link' target="_blank" >企业邮箱：kefu@qifuyuan.com</a>
                                <a className='navi-link' target="_blank">客服电话：400-0588-888</a>
                                <a className='navi-link' target="_blank">工作时间：工作日9:30-19:00</a>
                            </div>
                        </div>
                    </div>
                    <div className='data-box'>
                        <span>数据来源：</span>
                        <span>全国企业信用信息公示系统</span>
                        <span className="ms-2">国家知识产权局</span>
                        <span className="ms-2">商标局</span>
                        <span className="ms-2">版权局</span>
                    </div>
                    <div className='link-box'>
                        <span >友情链接：</span>
                        <a className="navi-link" href="https://www.intsig.com" target="_blank" rel="nofollow noopener noreferrer" >合合信息</a>
                        <a className="ms-2 navi-link" href="https://www.camcard.com" target="_blank" rel="nofollow noopener noreferrer" >名片全能王</a>
                        <a className="ms-2 navi-link" href="https://www.camscanner.com" target="_blank" rel="nofollow noopener noreferrer" >扫描全能王</a>
                        <a className="ms-2 navi-link" href="https://api.byteintel.cn/index.php/api/get_api_list" target="_blank" rel="nofollow noopener noreferrer" >开放平台</a>
                        <a className="ms-2 navi-link" href="http://www.drcnet.com.cn" target="_blank" rel="nofollow noopener noreferrer" >国务院发展研究中心信息网</a>
                        <a className="ms-2 navi-link" href="https://chandashi.com" target="_blank" rel="nofollow noopener noreferrer" >蝉大师</a>
                        <a className="ms-2 navi-link" href="https://www.quandashi.com" target="_blank" rel="nofollow noopener noreferrer" >权大师</a>
                        <a className="ms-2 navi-link" href="http://www.bidizhaobiao.com" target="_blank" rel="nofollow noopener noreferrer" >中国比地招标网</a>
                        <a className="ms-2 navi-link" href="http://www.js12377.cn/wyjb/index.jhtml?reportNext=1&amp;reportClass=2&amp;harmType=2" target="_blank" rel="nofollow noopener noreferrer" > 网络非法金融信息举报专区 </a>
                        <a className="ms-2 navi-link" href="https://www.jszhaobiao.com" target="_blank" rel="nofollow noopener noreferrer" >建设招标网</a>
                        <a className="ms-2 navi-link" href="https://www.ipe.org.cn" target="_blank" rel="nofollow noopener noreferrer" >蔚蓝地图</a>
                        <a className="ms-2 navi-link" href="https://www.djyanbao.com/index" target="_blank" rel="nofollow noopener noreferrer" >洞见研报</a>
                        <a className="ms-2 navi-link" href="https://suzhou.zbj.com" target="_blank" rel="nofollow noopener noreferrer" >猪八戒网</a>
                        <a className="ms-2 navi-link" href="https://www.youzan.com" target="_blank" rel="nofollow noopener noreferrer" >小程序商城系统</a>
                    </div>

                    <div className="d-flex justify-content-center mt-4 link-box"
                        style={{ borderTop: '0.5px solid hsla(0,0%,100%,.2)', lineHeight: '50px' }}>
                        <div><a className="navi-link"
                            href="https://beian.miit.gov.cn">© Copyright
                            2019 - 2023 | 北京企赋源科技有限公司 京ICP备2023026136号</a></div>
                    </div>
                </div>
            </div>
            <Modal wrapClassName='qr-modal' open={showQrcode} centered footer={null} width={340} onCancel={() => setShowQrcode(false)}>
                <div className='d-flex align-items-center justify-content-center pt-4 pb-4 flex-column'>
                    <img style={{ width: '160px', height: 'auto' }} src={require('../../images/qifuyuan.png')} />
                    <div className='modal-text'> 扫码预约体验</div>
                    <img className='qr-code' src={require('../../images/qr-code.png')} />
                </div>
            </Modal>
        </div>
    )
}

export default Home

const menu1List = [
    {
        title: '企赋源企业版标准版',
        subtitle: '商业调查、风控合规、营销拓客',
        icon: require('../../images/menu-icon-1.png'),
    },
    {
        title: '企赋源新品 - 金融人群版',
        subtitle: '对公营销、贷前尽调、贷后风控',
        icon: require('../../images/menu-icon-2.png'),
    },
    {
        title: '企赋源新品 - 采购风控版',
        subtitle: '准入排查、供应链风控、合规尽调',
        icon: require('../../images/menu-icon-3.png'),
    },
    {
        title: '企赋源新品 - 智能拓客版',
        subtitle: '新增企业、地图拓客、园区拓客',
        icon: require('../../images/menu-icon-4.png'),
    },
]

const menu2List = [
    {
        title: '数据API',
        icon: './menu-icon-a.svg',
    },
    {
        title: '数据定制',
        icon: './menu-icon-b.svg',
    },
    {
        title: '数据库产品',
        icon: './menu-icon-c.svg',
    },
]

const menu3List = [
    {
        title: '商标注册顾问',
        icon: './service-trademark.svg',
    },
    {
        title: '免费核名',
        icon: './service-check.svg',
    },
    {
        title: '普通公司注册',
        icon: './service-register.svg',
    },
    {
        title: '资质许可类服务',
        icon: './service-credential.svg',
    },
    {
        title: '体系认证服务',
        icon: './service-claim.svg',
    },
]

const menu4List = ['媒体通道', '商务合作', 'API合作', '数据生态合作']

const menu5List = [
    {
        title: '热门功能',
        list: [
            {
                title: '批量查询',
                icon: './hot-batch.svg',
            },
            {
                title: '找关系',
                icon: './hot-relation.svg',
            },
            {
                title: '地图查询',
                icon: './hot-map.svg',
            },
            {
                title: '报告商城',
                icon: './hot-report.svg',
            },
        ]
    },
    {
        title: '查专项',
        list: [
            {
                title: '财产线索',
                icon: './query-property.svg',
            },
            {
                title: '查招标',
                icon: './query-bidding.svg',
            },
            {
                title: '企业认证',
                icon: './query-claim.svg',
            },
            {
                title: '查老板',
                icon: './query-boss.svg',
            },
            {
                title: '查年报',
                icon: './query-annual.svg',
            },
            {
                title: '查融资',
                icon: './query-finance.svg',
            },
            {
                title: '查版权',
                icon: './query-copyright.svg',
            },
            {
                title: '查招聘',
                icon: './query-job.svg',
            },
            {
                title: '新增企业',
                icon: './query-newcompany.svg',
            },
            {
                title: '查合规',
                icon: './query-compliance.svg',
            },
            {
                title: '查全球',
                icon: './query-foreign.svg',
            },

        ]
    },
    {
        title: '企信企服',
        list: [
            {
                title: '商标注册',
                icon: './service-trademark.svg',
            },
            {
                title: '工商核名',
                icon: './service-check.svg',
            },
            {
                title: '公司注册',
                icon: './service-register.svg',
            },
            {
                title: '资质许可',
                icon: './service-credential.svg',
            },
            {
                title: '认证服务',
                icon: './service-claim.svg',
            },
        ]
    },
    {
        title: 'VIP服务',
        list: [
            {
                title: 'VIP会员',
                icon: './vip-member.svg',
            },
            {
                title: 'VIP团队版',
                icon: './vip-team.svg',
            },
            {
                title: '企业版',
                icon: './vip-enterprise.svg',
            },
        ]
    },
    {
        title: '企业服务',
        list: [
            {
                title: '银行营销',
                icon: './cooperation-bank.svg',
            },
            {
                title: '供应链风控',
                icon: './cooperation-risk.svg',
            },
            {
                title: '数据API',
                icon: './data-api.svg',
            },
            {
                title: '数据定制',
                icon: './data-c.svg',
            },
        ]
    },


]

const searchTypeList = [
    {
        type: '查企业',
        placeholder: '请输入企业名、人名、案号或案件名称',
    },
    {
        type: '查老板',
        placeholder: '请输入法定代表人、股东、高管名称',
    },
    {
        type: '查风险',
        placeholder: '请输入企业名、人名、案号、或案件名',
    },
    {
        type: '查商标',
        placeholder: '请输入商标名称、注册号、申请人',
    },
    {
        type: '查招标',
        placeholder: '请输入项目名称、关键词',
    },
]


const funcList = [
    {
        title: "高级搜索",
        subtitle: "支持多条件查询企业",
        icon: require('../../images/advance-search.png'),
    },
    {
        title: "批量查询",
        subtitle: "批量查询企业信息",
        icon: require('../../images/batch-search.jpg'),
    },
    {
        title: "区域查询",
        subtitle: "获取目标区域企业名单",
        icon: require('../../images/map-search.jpg'),
    },
    {
        title: "商标查询",
        subtitle: "先查询再注册",
        icon: require('../../images/mark-search.jpg'),
    },
    {
        title: "商机查询",
        subtitle: "智能拓客营销系统",
        icon: require('../../images/business_opportunity.png'),
    },
]

const citys = [
    {
        title: "北京",
        areas: ["东城", "西城", "朝阳", "丰台", "石景山", "海淀", "门头沟", "房山", "通州", "顺义", "昌平", "大兴", "怀柔", "平谷", "密云", "延庆"],
    },
    {
        title: "天津",
        areas: ["和平", "河东", "河西", "南开", "河北", "红桥", "东丽", "西青", "津南", "北辰", "武清", "宝坻", "滨海", "宁河", "静海", "蓟州"],
    },
    {
        title: "河北",
        areas: ["唐山", "秦皇岛", "邯郸", "邢台", "保定", "张家口", "承德", "廊坊", "衡水"],
    },
    {
        title: "山西",
        areas: ["太原", "大同", "阳泉", "长治", "晋城", "朔州", "晋中", "运城", "忻州", "临汾", "吕梁"],
    },

    {
        title: "内蒙古",
        areas: ["呼和浩特", "包头", "乌海", "赤峰", "通辽", "鄂尔多斯", "呼伦贝尔", "巴彦淖尔", "乌兰察布", "兴安盟", "锡林郭勒盟", "阿拉善盟"],
    },
    {
        title: "辽宁",
        areas: ["沈阳", "大连", "鞍山", "抚顺", "本溪", "丹东", "锦州", "营口", "阜新", "辽阳", "盘锦", "铁岭", "朝阳", "葫芦岛"],
    },
    {
        title: "吉林",
        areas: ["长春", "吉林", "四平", "辽源", "通化", "白山", "松原", "白城", "延边"],
    },
    {
        title: "黑龙江",
        areas: ["哈尔滨", "齐齐哈尔", "鸡西", "鹤岗", "双鸭山", "大庆", "伊春", "佳木斯", "七台河", "牡丹江", "黑河", "绥化", "大兴安岭"],
    },
    {
        title: "上海",
        areas: ["黄浦", "徐汇", "长宁", "静安", "普陀", "虹口", "杨浦", "闵行", "宝山", "嘉定", "浦东", "金山", "松江", "青浦", "奉贤", "崇明"],
    },
    {
        title: "江苏",
        areas: ["南京", "无锡", "徐州", "常州", "苏州", "南通", "连云港", "淮安", "盐城", "扬州", "镇江", "泰州", "宿迁"],
    },
    {
        title: "浙江",
        areas: ["杭州", "宁波", "温州", "嘉兴", "湖州", "绍兴", "金华", "衢州", "舟山", "台州", "丽水", "舟山", "新区"],
    },
    {
        title: "安徽",
        areas: ["合肥", "芜湖", "蚌埠", "淮南", "马鞍山", "淮北", "铜陵", "安庆", "黄山", "滁州", "阜阳", "宿州", "六安", "亳州", "池州", "宣城"],
    },
    {
        title: "福建",
        areas: ["福州", "厦门", "莆田", "三明", "泉州", "漳州", "南平", "龙岩", "宁德"],
    },
    {
        title: "江西",
        areas: ["南昌", "景德镇", "萍乡", "九江", "新余", "鹰潭", "赣州", "吉安", "宜春", "抚州", "上饶"],
    },
    {
        title: "山东",
        areas: ["济南", "青岛", "淄博", "枣庄", "东营", "烟台", "潍坊", "济宁", "泰安", "威海", "日照", "临沂", "德州", "聊城", "滨州", "菏泽"],
    },
    {
        title: "河南",
        areas: ["郑州", "开封", "洛阳", "平顶山", "安阳", "鹤壁", "新乡", "焦作", "濮阳", "许昌", "漯河", "三门峡", "南阳", "商丘", "信阳", "周口", "驻马店", "济源"],
    },
    {
        title: "湖北",
        areas: ["武汉", "黄石", "十堰", "宜昌", "襄阳", "鄂州", "荆门", "孝感", "荆州", "黄冈", "咸宁", "随州", "恩施", "神农架", "仙桃", "潜江", "天门"],
    },
    {
        title: "湖南",
        areas: ["长沙", "株洲", "湘潭", "衡阳", "邵阳", "岳阳", "常德", "张家界", "益阳", "郴州", "永州", "怀化", "娄底", "湘西"],
    },
    {
        title: "广东",
        areas: ["广州", "韶关", "深圳", "珠海", "汕头", "佛山", "江门", "湛江", "茂名", "肇庆", "惠州", "梅州", "汕尾", "河源", "阳江", "清远", "东莞", "中山", "潮州", "揭阳", "云浮"],
    },
    {
        title: "广西",
        areas: ["南宁", "柳州", "桂林", "梧州", "北海", "防城港", "钦州", "贵港", "玉林", "百色", "贺州", "河池", "来宾", "崇左"],
    },
    {
        title: "海南",
        areas: ["海口", "三亚", "三沙", "儋州", "定安", "屯昌", "澄迈", "临高", "白沙", "昌江", "乐东", "陵水", "保亭", "琼中", "五指山", "琼海", "文昌", "万宁", "东方"],
    },
    {
        title: "重庆",
        areas: ["万州", "涪陵", "渝中", "大渡口", "江北", "沙坪坝", "九龙坡", "南岸", "北碚", "綦江", "大足", "渝北", "巴南", "黔江", "长寿", "江津", "合川", "永川", "南川", "璧山", "铜梁", "潼南", "荣昌", "开州", "梁平", "武隆", "城口", "丰都", "垫江", "忠县", "云阳", "奉节", "巫山", "巫溪", "石柱", "秀山", "酉阳", "彭水"],
    },
    {
        title: "四川",
        areas: ["成都", "自贡", "攀枝花", "泸州", "德阳", "绵阳", "广元", "遂宁", "内江", "乐山", "南充", "眉山", "宜宾", "广安", "达州", "雅安", "巴中", "资阳", "阿坝", "甘孜", "凉山"],
    },
    {
        title: "贵州",
        areas: ["贵阳", "六盘水", "遵义", "安顺", "毕节", "铜仁", "黔西南", "黔东南", "黔南"],
    },
    {
        title: "云南",
        areas: ["昆明", "曲靖", "玉溪", "保山", "昭通", "丽江", "普洱", "临沧", "楚雄", "红河", "文山", "西双版纳", "大理", "德宏", "怒江", "迪庆"],
    },
    {
        title: "西藏",
        areas: ["拉萨", "日喀则", "昌都", "林芝", "山南", "那曲", "阿里"],
    },
    {
        title: "陕西",
        areas: ["西安", "铜川", "宝鸡", "咸阳", "渭南", "延安", "汉中", "榆林", "安康", "商洛"],
    },
    {
        title: "甘肃",
        areas: ["兰州", "嘉峪关", "金昌", "白银", "天水", "武威", "张掖", "平凉", "酒泉", "庆阳", "定西", "陇南", "临夏", "甘南"],
    },
    {
        title: "青海",
        areas: ["西宁", "海东", "海北", "黄南", "海南", "果洛", "玉树", "海西"],
    },
    {
        title: "宁夏",
        areas: ["银川", "石嘴山", "吴忠", "固原", "中卫"],
    },
    {
        title: "新疆",
        areas: ["乌鲁木齐", "克拉玛依", "吐鲁番", "哈密", "昌吉", "博尔塔拉", "巴音郭楞", "阿克苏", "克孜勒苏", "喀什", "和田", "伊犁", "塔城", "阿勒泰", "石河子", "阿拉尔", "图木舒克", "五家渠", "北屯", "铁门关", "双河", "可克达拉", "昆玉"],
    },
]

const professions = [
    {
        title: "教育",
        types: ["教育"],
    },
    {
        title: "公共管理、社会保障和社会组织",
        types: ["基层群众自治组织及其他组织", "国家机构", "人民政协、民主党派", "中国共产党机关", "社会保障", "群众团体、社会团体和其他成员组织"],
    },
    {
        title: "科学研究和技术服务业",
        types: ["研究和试验发展", "专业技术服务业", "科技推广和应用服务业"],
    },
    {
        title: "信息传输、软件和信息技术服务业",
        types: ["电信、广播电视和卫星传输服务", "软件和信息技术服务业", "互联网和相关服务"],
    },
    {
        title: "农、林、牧、渔业",
        types: ["渔业", "林业", "农、林、牧、渔专业及辅助性活动", "畜牧业", "农业"],
    },
    {
        title: "交通运输、仓储和邮政业",
        types: ["道路运输业", "航空运输业", "装卸搬运和仓储业", "邮政业", "水上运输业", "管道运输业", "多式联运和运输代理业", "铁路运输业"],
    },
    {
        title: "住宿和餐饮业",
        types: ["餐饮业", "住宿业"],
    },
    {
        title: "采矿业",
        types: ["煤炭开采和洗选业", "有色金属矿采选业", "其他采矿业", "非金属矿采选业", "石油和天然气开采业", "黑色金属矿采选业", "开采专业及辅助性活动"],
    },
    {
        title: "金融业",
        types: ["保险业", "资本市场服务", "其他金融业", "货币金融服务"],
    },
    {
        title: "租赁和商务服务业",
        types: ["租赁业", "商务服务业"],
    },
    {
        title: "居民服务、修理和其他服务业",
        types: ["机动车、电子产品和日用产品修理业", "其他服务业", "居民服务业"],
    },
    {
        title: "房地产业",
        types: ["房地产业"],
    },
    {
        title: "卫生和社会工作",
        types: ["社会工作", "卫生"],
    },
    {
        title: "建筑业",
        types: ["土木工程建筑业", "房屋建筑业", "建筑安装业", "建筑装饰、装修和其他建筑业"],
    },
    {
        title: "文化、体育和娱乐业",
        types: ["文化艺术业", "广播、电视、电影和录音制作业", "娱乐业", "新闻和出版业", "体育"],
    },
    {
        title: "水利、环境和公共设施管理业",
        types: ["水利管理业", "公共设施管理业", "生态保护和环境治理业", "土地管理业"],
    },
    {
        title: "制造业",
        types: ["金属制品、机械和设备修理业", "纺织服装、服饰业", "农副食品加工业", "金属制品业", "印刷和记录媒介复制业", "其他制造业", "黑色金属冶炼和压延加工业", "食品制造业酒、饮料和精制茶制造业", "石油、煤炭及其他燃料加工业", "纺织业", "医药制造业", "铁路、船舶、航空航天和其他运输设备制造业", "专用设备制造业", "化学纤维制造业", "造纸和纸制品业", "皮革、毛皮、羽毛及其制品和制鞋业", "家具制造业", "计算机、通信和其他电子设备制造业", "橡胶和塑料制品业", "废弃资源综合利用业", "非金属矿物制品业", "木材加工和木、竹、藤、棕、草制品业", "仪器仪表制造业", "有色金属冶炼和压延加工业", "电气机械和器材制造业", "文教、工美、体育和娱乐用品制造业", "通用设备制造业", "化学原料和化学制品制造业", "烟草制品业", "汽车制造业"],
    },
    {
        title: "国际组织",
        types: ["国际组织"],
    },
    {
        title: "电力、热力、燃气及水生产和供应业",
        types: ["电力、热力生产和供应业", "水的生产和供应业", "燃气生产和供应业"],
    },
    {
        title: "批发和零售业",
        types: ["批发业", "零售业"],
    },
]

const companys = [
    {
        name: '柬中实业投资发展（郑州市）合伙企业（有限合伙）',
        date: '2023-10-13',
        person: '李常秋',
        money: '10000',
        address: '',
    },
    {
        name: '宜春市吴军亮贸易中心',
        date: '2023-10-13',
        person: '吴军亮',
        money: '100000',
        address: '江西省宜春市丰城市尚庄街道云庄村委会',
    },
    {
        name: '郑州市郑东新区和众装饰材料店（个体工商户）',
        date: '2023-10-13',
        person: '刘轩',
        money: '5',
        address: '',
    },
    {
        name: '黄山市宏钧建设工程有限公司',
        date: '2023-10-13',
        person: '汪来宏',
        money: '6810',
        address: '安徽省黄山市徽州区岩寺镇城北工业园永兴一路33号',
    },
    {
        name: '洪雅宏裕生态农业科技有限公司',
        date: '2023-10-13',
        person: '杨家俊',
        money: '10000',
        address: '四川省眉山市洪雅县洪川镇人民路41号5楼',
    },
    {
        name: '广药白云牙膏（广州）有限公司',
        date: '2023-10-13',
        person: '孙维广',
        money: '50000 ',
        address: '广州市白云区同和街云祥路2号大院内自编25号楼101、102、103、202、203',
    },
    {
        name: '讷河市绿洲中草药种植有限公司',
        date: '2023-10-13',
        person: '林礼伟',
        money: '5000',
        address: '黑龙江省齐齐哈尔市讷河市卫东社区建华新村小区6号楼00单元01层03号',
    },
    {
        name: '宁夏煜新新能源科技有限公司',
        date: '2023-10-13',
        person: '高新艳',
        money: '600',
        address: '宁夏银川市金凤区尹家渠东侧枕水路南侧悦海新天购物广场14号办公楼2405室（自主申报）',
    },
    {
        name: '中湘旺丽黔（贵州）建设发展有限公司',
        date: '2023-10-13',
        person: '粱大宇',
        money: '16000',
        address: '贵州省贵阳市观山湖区金华园街道诚信北路8号绿地',
    },
]

const hots = [
    {
        name: '恒大地产集团有限公司',
        person: '赵长龙',
        money: '393979.6387',
    },
    {
        name: '中央汇金投资有限责任公司',
        person: '彭纯',
        money: '82820862.72',
    },
    {
        name: '中国投资有限责任公司',
        person: '彭纯',
        money: '155000000',
    },
    {
        name: '合肥三只羊网络科技有限公司',
        person: '张庆杨',
        money: '200',
    },
    {
        name: '恒大集团有限公司',
        person: '韩雪',
        money: '5300000',
    },
    {
        name: '华为技术有限公司',
        person: '赵明路',
        money: '4064113.182',
    },
    {
        name: '广州市凯隆置业有限公司',
        person: '韩雪',
        money: '4360000',
    },
    {
        name: '合肥三只羊创业投资有限公司',
        person: '张庆杨',
        money: '5000',
    },
    {
        name: '中国光大集团股份公司',
        person: '王江',
        money: '7813450.368',
    },
    {
        name: '广州市超丰置业有限公司',
        person: '孙蓬勃',
        money: '78400',
    },
    {
        name: '小米科技有限责任公司',
        person: '雷军',
        money: '185000',
    },
    {
        name: '香港中央結算有限公司',
        person: '-',
        money: '-',
    },
    {
        name: '安基 （BVI） 有限公司',
        person: '-',
        money: '-',
    },
    {
        name: '香港中央結算（代理人）有限公司',
        person: '-',
        money: '-',
    },
    {
        name: '中国银行股份有限公司',
        person: '葛海蛟',
        money: '29438779.1241',
    },
    {
        name: '浙江融意新材料有限公司',
        person: '谷峻',
        money: '1000',
    },
    {
        name: '赛力斯集团股份有限公司',
        person: '张正萍',
        money: '149712.4564',
    },
    {
        name: '中国建筑股份有限公司',
        person: '郑学选',
        money: '3000000',
    },
    {
        name: '中国建设银行股份有限公司',
        person: '田国立',
        money: '25001097.7486',
    },
    {
        name: '中国工商银行股份有限公司',
        person: '陈四清',
        money: '35640625.7089',
    },
    {
        name: '合肥领头羊文化传媒有限公司',
        person: '张庆杨',
        money: '100',
    },
    {
        name: '中国农发重点建设基金有限公司',
        person: '孙兰生',
        money: '5000000',
    },
    {
        name: '中央汇金资产管理有限责任公司',
        person: '黄学玲',
        money: '500000',
    },
    {
        name: '万科企业股份有限公司',
        person: '郁亮',
        money: '1099521.0218',
    },
    {
        name: '大有数字资源有限责任公司',
        person: '张长江',
        money: '3000',
    },
    {
        name: '中国蓝田总公司',
        person: '瞿兆玉',
        money: '40000',

    },
    {
        name: '中国中信集团有限公司',
        person: '朱鹤新',
        money: '20531147.635903',
    },
    {
        name: '中国农业银行股份有限公司',
        person: '谷澍',
        money: '34998303.3873',
    },
    {
        name: '威马汽车科技集团有限公司',
        person: 'SHEN HUI',
        money: '600000',
    },
    {
        name: '中国建筑第八工程局有限公司',
        person: '周可璋',
        money: '1521800',
    },
    {
        name: '比亚迪股份有限公司',
        person: '王传福',
        money: '291114.2855',
    },
    {
        name: '上海合合信息科技股份有限公司',
        person: '镇立新',
        money: '7500',
    },
    {
        name: '四川发展（控股）有限责任公司',
        person: '张宜刚',
        money: '8000000',
    },
    {
        name: '宁德时代新能源科技股份有限公司',
        person: '曾毓群',
        money: '244238.4964',
    },
    {
        name: '碧桂园地产集团有限公司',
        person: '杨文杰',
        money: '1531960.4768',
    },
    {
        name: '国家能源投资集团有限责任公司',
        person: '刘国跃',
        money: '13209466.11498',
    },
    {
        name: '华为投资控股有限公司',
        person: '赵明路',
        money: '5139633.3465',
    },
    {
        name: '中国冶金科工股份有限公司',
        person: '张孟星',
        money: '2072361.917',
    },
    {
        name: '中国铁建股份有限公司',
        person: '汪建平',
        money: '1357954.15',
    },
    {
        name: '昌河飞机工业（集团）有限责任公司',
        person: '周国臣',
        money: '47882.168662',
    },
    {
        name: '重庆小康控股有限公司',
        person: '张兴明',
        money: '20000',
    },
    {
        name: '乐视网信息技术（北京）股份有限公司',
        person: '刘延峰',
        money: '398944.0192',
    },
    {
        name: '中国航空工业集团有限公司',
        person: '谭瑞松',
        money: '6400000',
    },
    {
        name: '深圳市腾讯计算机系统有限公司',
        person: '马化腾',
        money: '6500',
    },
    {
        name: '北京国有资本运营管理有限公司',
        person: '赵及锋',
        money: '5000000',
    },
    {
        name: '雷丁汽车集团有限公司',
        person: '王德金',
        money: '10000',
    },
    {
        name: '中国保利集团有限公司',
        person: '刘化龙',
        money: '200000',
    },
    {
        name: '中建三局集团有限公司',
        person: '陈卫国',
        money: '1531800',
    },
]

const persons = [
    "赵长龙", "彭纯", "彭纯", "张庆杨", "韩雪", "赵明路", "韩雪", "张庆杨", "王江", "孙蓬勃", "雷军", "葛海蛟", "谷峻", "张正萍", "郑学选", "田国立", "陈四清", "张庆杨", "孙兰生", "黄学玲", "郁亮", "张长江", "瞿兆玉", "朱鹤新", "谷澍", "周可璋", "王传福", "镇立新", "张宜刚", "曾毓群", "杨文杰", "刘国跃", "赵明路", "张孟星", "汪建平", "周国臣", "张兴明", "刘延峰", "谭瑞松", "马化腾", "赵及锋", "王德金", "刘化龙", "陈卫国"
]


